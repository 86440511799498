<template>
  <v-container>
    <portal-form
      v-if="loaded"
      :info-card="form.infoCard"
      :info-card-text="form.infoCardText || ''"
      :sections="form.sections"
      :init-form-data="formData"
      :section-type="form.sectionType"
      :submit-label="formName === 'Academic Information' ? 'Done Reviewing' : form.submitLabel"
      :title="formName"
      :show-title="true"
      return-button="Back"
      @return="$router.push('/student/checklist')"
      @submit="save"></portal-form>
  </v-container>
</template>
<script>
import { computed, onMounted, reactive, ref } from '@vue/composition-api'
export default {
  components: {
    PortalForm: () => import('@/components/forms/Form')
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user)
    const term = computed(() => root.$route.params.term)
    const formName = computed(() => root.$route.params.formName)

    const formId = ref('')
    const revisionId = ref('')
    const form = reactive({})
    const loaded = ref(false)

    const formData = reactive({})

    onMounted(async () => {
      if (root.$store.state.student.termId === '') {
        const { data } = await root.$feathers.service('system/term').find({ query: { term: term.value } })
        if (data.length > 0) {
          root.$store.commit('student/setTermId', data[0]._id)
          root.$store.commit('student/setTerm', data[0].term)
        }
      }
      let confData = {}
      if (root.$store.state.student.confirmationId === '') {
        const { data } = await root.$feathers.service('student/confirmation').find({ query: { pidm: user.value.pidm, term: term.value } })
        if (data.length === 0) {
          root.$store.dispatch('main/snackbar', { active: true, timeout: 8000, color: 'error', text: 'No enrollment record was found for the given term.' })
          root.$router.replace('student/checklist')
          return
        } else {
          confData = data[0]
          root.$store.commit('student/setConfirmationId', data[0]._id)
        }
      } else {
        confData = await root.$feathers.service('student/confirmation').get(root.$store.state.student.confirmationId)
      }
      if (root.$store.state.student.termId !== '') {
        const { confirmation: { checklist } } = await root.$feathers.service('system/term').get(root.$store.state.student.termId)
        for (let i = 0; i < checklist.length; i++) {
          if (checklist[i].title === formName.value) {
            mapFields(confData, checklist[i].fieldMap)
            formId.value = checklist[i].form
            const { revisions: { active: activeRevision } } = await root.$feathers.service('forms/base').get(formId.value)
            if (activeRevision == null) {
              root.$store.dispatch('main/snackbar', { active: true, timeout: 8000, color: 'error', text: 'The requested form is not active. Please contact the Records Office for assistance.' })
              root.$route.replace('student/checklist')
            } else {
              revisionId.value = activeRevision
              const formDetail = await root.$feathers.service('forms/revision').get(activeRevision)
              for (const l in formDetail) {
                form[l] = formDetail[l]
              }
              loaded.value = true
            }
            return
          }
        }
      }
    })

    function mapFields (confData, fieldMap) {
      if (fieldMap == null) return
      for (const { path, label, blockLabel } of fieldMap) {
        const [first, second, third] = path.split('.')
        // Make sure the first level exists in the path
        if (first in confData && confData[first] != null) {
          // Check to see if there is a second level
          if (second != null) {
            // If there is a second level, then we need to make sure that the first level is an object, otherwise this is an error
            if (typeof (confData[first]) === 'object') {
              if (Array.isArray(confData[first])) {
                if (typeof (confData[first][0]) === 'object') {
                  // Repeatable block
                  addRepeatableBlock(confData[first], second, blockLabel, label)
                } else {
                  // Error if we get here!!!
                }
              } else {
                // sub-document; check for a third level
                if (third != null) {
                  if (typeof (confData[first][second]) === 'object') {
                    if (Array.isArray(confData[first][second])) {
                      // Repeatable block
                      addRepeatableBlock(confData[first][second], third, blockLabel, label)
                    } else {
                      // sub-document
                      const val = confData[first][second][third]
                      if (val != null && val !== '') formData[label] = val
                    }
                  }
                } else {
                  const val = confData[first][second]
                  if (val != null && val !== '') formData[label] = val
                }
              }
            } else {
              // Error if we get here!!!
            }
          } else {
            // Just a top-level piece of data; just add it to formData
            const val = confData[first]
            if (val != null && val !== '') formData[label] = val
          }
        }
      }
    }

    function addRepeatableBlock (confArray, pathPart, blockLabel, fieldLabel) {
      if (!(blockLabel in formData)) formData[blockLabel] = []
      for (let i = 0; i < confArray.length; i++) {
        if (pathPart in confArray[i] && confArray[i][pathPart] != null && confArray[i][pathPart] !== '') {
          if (!(i in formData[blockLabel])) {
            formData[blockLabel].push({ [fieldLabel]: confArray[i][pathPart] })
          } else {
            formData[blockLabel].splice(i, 1, { ...formData[blockLabel][i], [fieldLabel]: confArray[i][pathPart] })
          }
        }
      }
    }

    async function save () {
      let dataArr = []
      for (const field in formData) {
        if (Array.isArray(formData[field]) && formData[field].length > 0 && typeof (formData[field][0]) === 'object') {
          // Repeatable block; convert the sub-objects into arrays for storage in the database
          let value = []
          for (let i = 0; i < formData[field].length; i++) {
            let temp = []
            for (const l in formData[field][i]) {
              temp.push({ field: l, value: formData[field][i][l] })
            }
            value.push(temp)
          }
          dataArr.push({ field, value })
        } else {
          dataArr.push({ field, value: formData[field] })
        }
      }
      // Add a new form submission to the formId
      const submittedDate = new Date()
      let submission = ''
      try {
        const data = await root.$feathers.service('forms/submission').create({
          user: user.value._id,
          name: user.value.name,
          form: {
            name: formName.value,
            base: formId.value,
            revision: revisionId.value
          },
          status: 'Submitted',
          submittedDate,
          dataArr
        })
        if ('_id' in data) submission = data._id
      } catch (e) {
        root.$store.dispatch('main/snackbar', { active: true, type: 'error', timeout: 8000, text: 'Error saving confirmation data: ' + e })
      }
      // Patch the confirmation document's checklist to include the title, submittedDate, and submissionId for this submission
      if (submission !== '') {
        const checklist = {
          title: formName.value,
          submitted: submittedDate,
          submission
        }
        try {
          await root.$feathers.service('student/confirmation').patch(root.$store.state.student.confirmationId, { $push: { checklist } })
          root.$store.dispatch('main/snackbar', { active: true, type: 'success', timeout: 6000, text: formName.value + ' confirmation saved.' })
          root.$router.push('/student/checklist')
        } catch (e) {
          root.$store.dispatch('main/snackbar', { active: true, type: 'error', timeout: 8000, text: 'Error updating confirmation: ' + e })
        }
      }
    }

    return {
      user,
      term,
      formName,
      formId,
      form,
      loaded,
      formData,
      save
    }
  }
}
</script>
